import React, { useState, useEffect } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import homeLogo from '../../assets/home-logo.png';
import logo from '../../assets/logo-small.png';
import { AiOutlineMenu, AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Divider from "@material-ui/core/Divider";

function WhichMedicine() {

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('zendesk');
    navigate('/');
  }

  const goToHome = () => {
    navigate('/home');
  }

  const goToReminders = () => {
    navigate('/reminders-medicine');
  }

  const goToFriendsMedicine = () => {
    navigate('/friends-medicine');
  }

  useEffect(() => {
    const token = (localStorage.getItem('token') as string);

    if (!token) {
      navigate('/');
    }
  }, [navigate])

  return (
    <div className="Home">
      <div className="Home-header-container">
        <img src={logo} className="Home-header-logo" alt="logo" />
        <AiOutlineMenu className='Home-icon' onClick={toggleDrawer} />
      </div>
      <header className="Home-header">
        <div className="Home-link-container">
          <div className="Home-link" onClick={goToReminders} >
            <p className='Home-link-title'>Meus Medicamentos</p>
          </div>
          <div className="Home-link" onClick={goToFriendsMedicine} >
            <p className='Home-link-title'>Medicamentos dos amigos</p>
          </div>
        </div>
      </header>
      <Drawer open={isOpen} onClose={toggleDrawer} direction='right'>
        <div className="Home-drawer-link" onClick={goToHome} >
          <AiOutlineHome color='gray' size={20} />
          <p className='Home-drawer-link-text'>Home</p>
        </div>
        <Divider />
        <div className="Home-drawer-link" onClick={logout} >
          <AiOutlineLogout color='gray' size={18} />
          <p className='Home-drawer-link-text'>Sair</p>
        </div>
        <Divider />
      </Drawer>
    </div>
  );
}

export default WhichMedicine;
