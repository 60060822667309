//localStorage.setItem('items', JSON.stringify(items));

import baseService from '../BaseService';

const SubscribeService = (name: string, password: string, email: String, phone: string, gender: string) => {

    localStorage.setItem('token', JSON.stringify({ email, password, name, phone, gender }));
    return baseService.post('/v1/user', { name, password, email, phone, gender }, { headers: { "Content-Type": "application/json" } });
};

export default SubscribeService;