import React, { useState, useEffect } from 'react';
import './index.css';
import Drawer from 'react-modern-drawer';
import logo from '../../assets/logo-small.png';
import healthCicleLogo from '../../assets/img-health-cicle.png';
import healthCicleChart from '../../assets/health-cicle-chart.png';
import 'react-modern-drawer/dist/index.css';
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import Divider from "@material-ui/core/Divider";

function App() {

    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const logout = () => {
        localStorage.removeItem('token');
        navigate('/');
    }

    const goToHome = () => {
        navigate('/home');
    }

    useEffect(() => {
        const token = (localStorage.getItem('token') as string);

        if (!token) {
            navigate('/');
        }
    }, [navigate])

  return (
    <div className="Health-cicles">
      <div className="Home-header-container">
        <img src={logo} className="Health-cicle-header-logo" alt="logo" />
        <AiOutlineMenu className='Home-icon' onClick={toggleDrawer}/>
      </div>
      <header className="Health-cicle-header">
        <img src={healthCicleLogo} className="Health-cicle-title-logo" alt="logo" />
        <p className='Health-cicle-title-text'>✨ Abaixo, estão seus objetivos de 2023, carinhosamente elaborados pela sua equipe de saúde, pensando especialmente em você! Estamos juntos nessa jornada. Qualquer dúvida, é só chamar. 💪🌟</p>
        <p className='Health-cicle-title-text'>Não deixe de marcar cada etapa que completar 😊</p>
        <ul className="Health-cicle-detail-container">
            <li className='Health-cicle-name'>Consulta anual em ginecologia</li>
            <li className='Health-cicle-name'>Exames laboratoriais</li>
            <li className='Health-cicle-name-gray'>Mamografia</li>
        </ul>

        <img src={healthCicleChart} className="Health-cicle-chart-logo" alt="logo" />

      </header>
      <Drawer open={isOpen} onClose={toggleDrawer} direction='right'>
        <div className="Home-drawer-link" onClick={goToHome} >
            <AiOutlineHome color='gray' size={20} />
            <p className='Home-drawer-link-text'>Home</p>
        </div>
        <Divider />
        <div className="Home-drawer-link" onClick={logout} >
            <AiOutlineLogout color='gray' size={18} />
            <p className='Home-drawer-link-text'>Sair</p>
        </div>
        <Divider />
      </Drawer>
    </div>
  );
}

export default App;
