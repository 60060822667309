import React, { useEffect, useState } from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import SubscribeService from '../../services/SubscribeService';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/logo-small.png';

function App() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('male');
  const [birth, setBirth] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] : any = useState();
  const [errorMessage, setErrorMessage] : any = useState();


  const navigate = useNavigate();

  const delay = (delayInms: number) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  const subscribe = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if(!password || !confirmPassword || !name || !email || !gender || !birth || !phone) {
      setError(true);
      setErrorMessage('Preencha todos os campos!');
      await delay(3000);
      setError(false);
      return;
    }

    if(password !== confirmPassword) {
      setError(true);
      setErrorMessage('As senhas não conferem!');
      await delay(3000);
      setError(false);
      return;
    }

    SubscribeService(name, password, email, phone, gender)
    .then(response => navigate('/'))
    .catch(async error => {
      setError(error);
      setErrorMessage('Erro ao efetuar cadastro!');
      await delay(3000);
      setError(false);
    });
  }

  useEffect(() => {
    const token = (localStorage.getItem('token') as string);

    if (token && token !== '') {
      localStorage.removeItem('token');
    }
  }, [])

  return (
    <div className="App">
      <div className="Subscribe-header-container">
        <img src={logo} className="Subscribe-logo" alt="logo" />

      </div>
      <form className="Subscribe-form" onSubmit={subscribe} >
        <p className='Subscribe-form-title'>Nome Completo</p>
        <input className="Subscribe-form-input" type="text" value={name} onChange={(e) => setName(e.target.value)} name="name" placeholder="Nome" />
        <p className='Subscribe-form-title'>Sexo Biológico</p>
        <select className="Subscribe-form-input" value={gender} defaultValue="male" onChange={(e) => setGender(e.target.value)} >
            <option value="male">Masculino</option>
            <option value="female">Feminino</option>
        </select>
        <p className='Subscribe-form-title'>Data de Nascimento</p>
        <input className="Subscribe-form-input" type="date" value={birth} onChange={(e) => setBirth(e.target.value)} name="birth" placeholder="dd/mm/aaaa" />
        <p className='Subscribe-form-title'>E-mail</p>
        <input className="Subscribe-form-input" type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="usuario@dominio.com.br" />
        <p className='Subscribe-form-title'>Telefone móvel</p>
        <input className="Subscribe-form-input" type="number" value={phone} onChange={(e) => setPhone(e.target.value)} name="phone" placeholder="21 99999999" />
        <p className='Subscribe-form-title'>Senha</p>
        <input className="Subscribe-form-input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" placeholder="Senha" />
        <p className='Subscribe-form-title'>Confirmar Senha</p>
        <input className="Subscribe-form-input" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} name="password" placeholder="Confirmar Senha" />
        <input className="Subscribe-form-submit" type="submit" value="Criar" />
      </form>

      <Link className="App-link" to="/" >Já tem cadastro? Faça login aqui</Link>

      {error && <div className='App-error-container' >{errorMessage}</div>}
    </div>
  );
}

export default App;
