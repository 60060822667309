import baseService from '../BaseService';

const GetUserByEmailService = (email: string) => {
  return baseService.get(`/v1/friends/user/email/${email}`, { headers: { "Content-Type": "application/json" } })
    .then(response => {
      localStorage.setItem('user-by-email', JSON.stringify(response.data));
      return response.data;     })
    .catch(() => {
      localStorage.setItem('user-by-email', JSON.stringify(null));
      return null;
    });
};

export default GetUserByEmailService;
