import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo-small.png';
import './index.css';
import { Link } from 'react-router-dom';
import { AddMedicineService } from '../../services';
import EditMedicineService from '../../services/EditMedicineService';
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineMenu, AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import Drawer from 'react-modern-drawer';
import Divider from "@material-ui/core/Divider";

function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
}

function AddMedicine() {

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState('');
  const [dose, setDose] = useState<number>();
  const [intervalo, setIntervalo] = useState<number>();
  const [duracao, setDuracao] = useState<number>();
  const [alwaysInUse, setAlwaysInUse] = useState(false);
  const [medicineToEdit, setMedicineToEdit] = useState(false);
  const [medicineId, setMedicineId] = useState();

  const [primeiroHorario, setPrimeiroHorario] = useState('');

  const [error, setError] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  
  const reset = location.state ? location.state.reset : false;

  const delay = (delayInms: number) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    localStorage.setItem('medicineToEdit', '');
    event.preventDefault();
    if (medicineToEdit) {
      EditMedicineService((medicineId ? medicineId : 0), name, (dose ? dose : 0).toString()).then(response => {
        navigate(-1);
        setIsLoading(false);
      })
      .catch(async error => {
        setIsLoading(false);
        setError(error);
        await delay(3000);
        setError(undefined);
      });
    } else {
      AddMedicineService(name, (dose? dose : 0).toString(), (intervalo ? intervalo : 0).toString(), alwaysInUse ? 100 : (duracao ? duracao : 0), primeiroHorario)
      .then(response => {
        navigate(-1);
        setIsLoading(false);
      })
      .catch(async error => {
        setIsLoading(false);
        setError(error);
        await delay(3000);
        setError(undefined);
      });
    }
  }

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const logout = () => {
      localStorage.removeItem('token');
      navigate('/');
  }

  const goToHome = () => {
      navigate('/home');
  }

  useEffect(() => {
    const token = (localStorage.getItem('token') as string);
    const somethingToEdit = localStorage.getItem('medicineToEdit');

    if(somethingToEdit) {
      setMedicineToEdit(true);
      const medicine = JSON.parse(somethingToEdit);
      setMedicineId(medicine.id);
      setName(medicine.name);
      setDose(medicine.dose);
      setIntervalo(medicine.hoursinterval);
      setPrimeiroHorario(medicine.startedtime);

      let daysInterval = (new Date(medicine.finaldate).getTime() - new Date(medicine.starteddate).getTime())/24/60/60/1000;

      if(daysInterval >= 100) {
        setAlwaysInUse(true);
      } else {
        setDuracao((daysInterval ? daysInterval : 0));
      }
    }

    if (!token) {
        navigate('/');
    }
  }, [])

  return (
    <div className="Add_medicine">
      {isLoading && <LoadingSpinner /> }
      <div className="Add_medicine-header">
        <div className="Add_medicine-header-container">
          <img src={logo} className="Add_medicine-logo" alt="logo" />
          <AiOutlineMenu className='Add_medicine-icon' onClick={toggleDrawer}/>

        </div>
        <div className="Health-cicle-text">{`${medicineToEdit ? 'Editar' : 'Adicionar'} Medicamento`}</div>
      </div>

      <form className="Add_medicine-form" onSubmit={onSubmit} >
        <div className="Add_medicine-form-input-container">
        <p className='Add_medicine-form-title'>Nome</p>
        <input className="Add_medicine-form-input" type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Nome do Medicamento" />
        <p className='Add_medicine-form-title'>Dose</p>
        <input className="Add_medicine-form-input" type="number" value={dose} onChange={(e) => setDose(e.target.value as unknown as number)} placeholder="Dosagem" />
        <p className='Add_medicine-form-title'>Intervalo (em horas)</p>
        <input className="Add_medicine-form-input" type="number" disabled={medicineToEdit} value={intervalo} onChange={(e) => setIntervalo(e.target.value as unknown as number)} placeholder="Intervalo do Medicamento (em horas)" />
        <p className='Add_medicine-form-title'>Duração (em dias)</p>
        <div>
          <input type="checkbox" id="scales" disabled={medicineToEdit} name="scales" onChange={() => setAlwaysInUse((prev) => !prev)} checked={alwaysInUse} />
          <span>Uso Contínuo</span>
        </div>
        <input className="Add_medicine-form-input" disabled={alwaysInUse || medicineToEdit} type="number" value={duracao} onChange={(e) => setDuracao(e.target.value as unknown as number)} placeholder="Duração (em dias)" />
        <p className='Add_medicine-form-title'>Horário da primeira dose</p>
        <input className="Add_medicine-form-input" type="time" disabled={medicineToEdit} value={primeiroHorario} onChange={(e) => setPrimeiroHorario(e.target.value)} placeholder="Horário da primeira dose" />
        </div>
        <input className="Add_medicine-form-submit" type="submit" value={`${medicineToEdit ? 'Editar' : 'Adicionar'}`} />
      </form>

      <Drawer open={isOpen} onClose={toggleDrawer} direction='right'>
        <div className="Home-drawer-link" onClick={goToHome} >
            <AiOutlineHome color='gray' size={20} />
            <p className='Home-drawer-link-text'>Home</p>
        </div>
        <Divider />
        <div className="Home-drawer-link" onClick={logout} >
            <AiOutlineLogout color='gray' size={18} />
            <p className='Home-drawer-link-text'>Sair</p>
        </div>
        <Divider />
      </Drawer>

      {error && <div className='Add_medicine-error-container' >Erro ao cadastrar medicamento!</div>}
      {reset && <div className='Add_medicine-assert-container' >Medicamento cadastrado com sucesso!</div>}
    </div>
  );
}

export default AddMedicine;
