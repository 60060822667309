import React, { useContext, useEffect, useState } from 'react';
import logo from '../../assets/soul-logo.png';
import './index.css';
import { Link } from 'react-router-dom';
import LoginService from '../../services/LoginService';
import { useNavigate, useLocation } from "react-router-dom";

function App() {

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const reset = location.state ? location.state.reset : false;

  const delay = (delayInms: number) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  const login = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    LoginService(phone, password)
      .then(response => navigate('/home'))
      .catch(async error => {
        setError(error);
        await delay(3000);
        setError(undefined);
      });
  }

  useEffect(() => {
    const token = (localStorage.getItem('token') as string);

    if (token && token !== '') {
      localStorage.removeItem('token');
    }
  }, [])

  return (
    <div className="App-login">
      <div className="App-form-container">
        <header className="App-header">
          <img src={logo} className="Login-logo" alt="logo" />
        </header>
        <form className="App-form" onSubmit={login} >
          <input className="Login-form-input" type="text" name="name" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Telefone" />
          <input className="Login-form-input" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Senha" />
          <input className="Login-form-submit" type="submit" value="Login" />
        </form>

        <Link className="Login-link" to="/forgot-password" >Não lembro minha senha</Link>
      </div>
      <div>
        <Link className="Login-subscribe-link" to="/subscribe" >+ Nova conta</Link>
      </div>



      {error && <div className='App-error-container' >Erro ao efetuar login!</div>}
      {reset && <div className='App-assert-container' >Senha enviada via SMS!</div>}
    </div>
  );
}

export default App;
