import React, { useEffect } from 'react';
import './index.css';
import 'react-modern-drawer/dist/index.css';
import check from '../../assets/check.png';
import notCheck from '../../assets/not-checked.png';
import faceSad from '../../assets/face-sad.png';
import soulLogo from '../../assets/soul-logo.png';
import { Button } from '@material-ui/core';
import CheckDoseService from '../../services/CheckDoseService';
import { useParams, useLocation } from 'react-router-dom';


function App() {
  let params = window.location.search.substring(1).split('=');
  useEffect(() => {
    if (params[1]) {
      CheckDoseService(params[1]).finally()
    }
  }, []);

  return (
    <div className="medicine-check-confirmation" >
      <div className="image-check-container">
        {params[1] && (
          <img src={check} className="image-check" alt="logo" />
        )}
        {!params[1] && (
          <img src={faceSad} className="image-check" alt="logo" />
        )}
      </div>
      <div className='check-confirmation-title'>
        <hr />
        {params[1] && (
          <h2>
            Obrigado por validar seu medicamento.
          </h2>
        )}
        {!params[1] && (
          <h2>
            Não foi possivel validar seu medicamento.
          </h2>
        )}
        <br />
        <div>
          Você pode ir para o App agora clicando no botão abaixo.
          <br />
          <br />
          <Button className='geolocation-button'>
            {params[1] && (
              <b onClick={() => window.open('https://app.prosaude.com.br', '_blank')}>Ir para o app</b>
            )}
            {!params[1] && (
              <b onClick={() => window.open('https://app.prosaude.com.br', '_blank')}>Validar o medicamento no app</b>
            )}
          </Button>
        </div>

        <br />
        <footer>
          <img src={soulLogo} className="image-soul-logo" alt="logo" />
        </footer>
      </div>
    </div>
  );
}

export default App;
