import React, { useState } from 'react';
import logo from '../../assets/soul-logo.png';
import './index.css';
import { Link } from 'react-router-dom';
import ForgetPasswordService from '../../services/ForgetPasswordService';
import { useNavigate } from "react-router-dom";

function App() {

  const [number, setNumber] = useState('');
  const [error, setError] = useState();

  const navigate = useNavigate();

  const delay = (delayInms: number) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  const sendPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    ForgetPasswordService(number)
      .then(response => navigate('/login', { state: { reset: true } }))
      .catch(async error => {
        setError(error);
        await delay(3000);
        setError(undefined);
      });
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <form className="App-form" onSubmit={sendPassword} >
        <input className="App-form-input" type="text" name="name" value={number} onChange={(e) => setNumber(e.target.value)} placeholder="Digite seu telefone" />
        <input className="App-form-submit" type="submit" value="Recuperar minha senha" />
      </form>

      <Link className="App-link" to="/subscribe" >Não tem cadastro? Cadastre-se aqui</Link>

      {error && <div className='App-error-container' >O usuário não foi encontrado no sistema.</div>}
    </div>
  );
}

export default App;
