import baseService from '../BaseService';

const GetDoseService = (date: string) => {
    const userId = (localStorage.getItem('userId') as string);

    return baseService.get(`/v1/user/doses/${userId}/${date}`, { headers: { "Content-Type": "application/json" } })
        .then(response =>
            localStorage.setItem('doses-today', JSON.stringify(response.data))
        ).catch((err) => {
            localStorage.setItem('doses-today', JSON.stringify([]));
        });
};

export default GetDoseService;