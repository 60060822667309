import baseService from '../BaseService';

const GetDoseServiceFriend = (id) => {
    return baseService.get(`/v1/doses/${id}`, { headers: { "Content-Type": "application/json" } })
        .then(response =>
            localStorage.setItem('doses-friend-today', JSON.stringify(response.data))
        ).catch((err) => {
            localStorage.setItem('doses-friend-today', JSON.stringify([]));
        });
};

export default GetDoseServiceFriend;