import baseService from '../BaseService';

const SendFriendInvite = (person1Id: string, person2Id: string, person1Medicines: boolean, person2Medicines: boolean) => {

  return baseService.post('v1/friends/invite', { person1Id, person2Id, person1Medicines, person2Medicines }, { headers: { "Content-Type": "application/json" } })
    .then(response => {
      localStorage.setItem('person1Id', response.data.person1Id);
      localStorage.setItem('person2Id', response.data.person2Id)
      localStorage.setItem('person1Medicines', response.data.person1Medicines)
      localStorage.setItem('person2Medicines', response.data.person2Medicines)
    })
};

export default SendFriendInvite;