import baseService from '../BaseService';

const GetFriendsMedicineCount = (id) => {
  return baseService.get(`/v1/friends/medicines-count/${id}`, { headers: { "Content-Type": "application/json" } })
    .then(response =>
      localStorage.setItem('dosesCount', JSON.stringify(response.data))
    ).catch((err) => {
      localStorage.setItem('dosesCount', JSON.stringify([]));
    })
};

export default GetFriendsMedicineCount;