import baseService from '../BaseService';

const GetDosesService = () => {
    const userId = localStorage.getItem('userId');

    return baseService.get(`/v1/user/medicine/${userId}`, { headers: { "Content-Type": "application/json" } })
        .then(response =>
            localStorage.setItem('doses', JSON.stringify(response.data))
        ).catch((err) => {
            localStorage.setItem('doses', JSON.stringify([]));
        })
};

export default GetDosesService;