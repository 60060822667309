import React, { useState, useEffect } from 'react';
import './index.css';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineHome, AiOutlineLogout, AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import Divider from "@material-ui/core/Divider";
import { Tab, Tabs, AppBar, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Calendar from 'react-calendar';
import styled from 'styled-components';

function App() {

    const [isOpen, setIsOpen] = useState(false);
    const [isMedicineOpen, setIsMedicineOpen] = useState(false);
    const [isMedicine1Open, setIsMedicine1Open] = useState(false);

    type ValuePiece = Date | null;

    type Value = ValuePiece | [ValuePiece, ValuePiece];

    const [valueCalendar, onChangeCalendar] = useState<Value>(new Date());


    const navigate = useNavigate();

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const logout = () => {
        localStorage.removeItem('token');
        navigate('/');
    }

    const goToHome = () => {
        navigate('/home');
    }

    useEffect(() => {
        const token = (localStorage.getItem('token') as string);

        if (!token) {
            navigate('/');
        }
    }, [navigate])

    const [value, setValue] = React.useState("one");

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    const MedicineTabs = withStyles({
        root: {
            backgroundColor: 'transparent',
            color: 'gray',
        },
        indicator: {
          backgroundColor: '#B0DECF',
        },
      })(Tabs);

      const MedicineAppBar = withStyles({
        root: {
            backgroundColor: 'white',
            color: 'gray',
            zIndex: 'unset'
        },
        indicator: {
          backgroundColor: '#B0DECF',
        },
      })(AppBar);

      interface TabPanelProps {
        children?: React.ReactNode;
        index: any;
        value: any;
      }

      const styles = {
        root: {
            width: '100%',
            padding: '0px 10px 10px 10px'
        }
      };

      const MedicineBox = withStyles(styles)(Box);

      const CalendarContainer = styled.div`
      margin: auto;
      margin-top: 20px;
      padding: 10px;
      border-radius: 3px;
      
      .react-calendar__navigation {
          display: flex;
      
          .react-calendar__navigation__label {
            font-weight: bold;
          }
      
          .react-calendar__navigation__arrow {
            flex-grow: 0.333;
          }
        }

      .react-calendar__month-view__weekdays {
          text-align: center;
          color: gray;
        }
      
      button {
          margin: 3px;
          background-color: #6f876f;
          border: 0;
          border-radius: 3px;
          color: white;
          padding: 5px 0;
      
          &:hover {
            background-color: #556b55;
          }
      
          &:active {
            background-color: #a5c1a5;
          }
        }

      /* ~~~ day grid styles ~~~ */
      /* ... */

      /* ~~~ neighboring month & weekend styles ~~~ */
      .react-calendar__month-view__days__day--neighboringMonth {
          opacity: 0.7;
      }
      .react-calendar__month-view__days__day--weekend {
          color: #dfdfdf;
      }

      .react-calendar__tile--range {
          background-color: royalblue;
          box-shadow: 0 0 6px 2px rgba(0,0,0,0.5);
      }
      `;

      function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`a11y-tabpanel-${index}`}
            className="Medicine-reminder-tab-content"
            aria-labelledby={`a11y-tab-${index}`}
            {...other}
          >
            {value === index && (
              <MedicineBox >
                <Typography>{children}</Typography>
              </MedicineBox>
            )}
          </div>
        );
      }

  return (
    <div className="Agenda-reminders">
      <div className="Home-header-container">
        <div className="Medicine-reminder-text">Agenda</div>
        <AiOutlineMenu className='Home-icon' onClick={toggleDrawer}/>
      </div>
      <header className="Reminder-medicine-header">
         <MedicineAppBar className="Reminder-medicine-link" position="static" >
            <MedicineTabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="wrapped label tabs example"
            >
                <Tab value="one" label="Lista" />
                <Tab value="two" label="Calendário" />
            </MedicineTabs>
         </MedicineAppBar>
         <TabPanel value={value} index="one">
            <p className='Medicine-reminder-title-text'>Próximas agendas:</p>
            <div className="Medicine-detail-container">
                <div className='Medicine-container' onClick={() => setIsMedicineOpen(!isMedicineOpen)}>
                    <p className='Medicine-name'>Consulta Pneumologia</p>
                    {isMedicineOpen ? <AiOutlineDown className='Medicine-details-icon' /> : <AiOutlineRight className='Medicine-details-icon' />}
                </div>
                {isMedicineOpen && <ul className='Medicine-details-ul'>
                    <li className='Medicine-detail'>
                        Nome do Médico: Antônio da Silva Sauro
                    </li>
                    <li className='Medicine-detail'>
                        Clínica: Hospital Copa'Dor
                    </li>
                    <li className='Medicine-detail'>
                        Horário: 12/09/2023 11:55
                    </li>
                    <li className='Medicine-detail'>
                        Tipo: consulta médica
                    </li>
                </ul>}
            </div>
            <div className="Medicine-detail-container">
                <div className='Medicine-container' onClick={() => setIsMedicine1Open(!isMedicine1Open)}>
                    <p className='Medicine-name'>Exame de Sangue completo</p>
                    {isMedicine1Open ? <AiOutlineDown className='Medicine-details-icon' /> : <AiOutlineRight className='Medicine-details-icon' />}
                </div>
                {isMedicine1Open && <ul className='Agenda-details-ul'>
                    <li className='Medicine-detail'>
                        Nome do Enfermeiro: Manoela da Silva Sauro
                    </li>
                    <li className='Medicine-detail'>
                        Clínica: Labs+
                    </li>
                    <li className='Medicine-detail'>
                        Horário: 22/09/2023 08:45
                    </li>
                    <li className='Medicine-detail'>
                        Tipo: exame médico
                    </li>
                </ul>}
            </div>
         </TabPanel>
         <TabPanel value={value} index="two">
            <CalendarContainer>
                <Calendar onChange={onChangeCalendar} value={valueCalendar} />
            </CalendarContainer>
            <div className="Medicine-detail-container">
                <div className='Medicine-container' onClick={() => setIsMedicineOpen(!isMedicineOpen)}>
                    <p className='Medicine-name'>Consulta Pneumologia</p>
                    {isMedicineOpen ? <AiOutlineDown className='Medicine-details-icon' /> : <AiOutlineRight className='Medicine-details-icon' />}
                </div>
                {isMedicineOpen && <ul className='Medicine-details-ul'>
                    <li className='Medicine-detail'>
                        Nome do Médico: Antônio da Silva Sauro
                    </li>
                    <li className='Medicine-detail'>
                        Clínica: Hospital Copa'Dor
                    </li>
                    <li className='Medicine-detail'>
                        Horário: 12/09/2023 11:55
                    </li>
                    <li className='Medicine-detail'>
                        Tipo: consulta médica
                    </li>
                </ul>}
            </div>
         </TabPanel>
      </header>
      <Drawer open={isOpen} onClose={toggleDrawer} direction='right'>
        <div className="Home-drawer-link" onClick={goToHome} >
            <AiOutlineHome color='gray' size={20} />
            <p className='Home-drawer-link-text'>Home</p>
        </div>
        <Divider />
        <div className="Home-drawer-link" onClick={logout} >
            <AiOutlineLogout color='gray' size={18} />
            <p className='Home-drawer-link-text'>Sair</p>
        </div>
        <Divider />
      </Drawer>
    </div>
  );
}

export default App;
