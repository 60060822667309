import React, { useEffect, useState } from 'react';
import './index.css';
import 'react-modern-drawer/dist/index.css';
import logo from '../../assets/logo-small.png';
import { useNavigate } from "react-router-dom";
import GetPartnerService from '../../services/GetPartnerService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AiOutlineHome, AiOutlineLeft, AiOutlineLogout, AiOutlineMenu } from 'react-icons/ai';
import { Button, Divider, Drawer } from '@material-ui/core';

interface Partner {
  name: string;
  address: string;
  number: string;
  city: string;
  zipcode: string;
  phone: string;
  partner_type: string;
}

function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
}

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [partner, setPartner] = useState<Partner[]>([]);

  useEffect(() => {
    setIsLoading(true);
    GetPartnerService().then((response) => {
      const partner = JSON.parse(localStorage.getItem('partner') || "{}");
      setPartner(partner);
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
    });
  }, []);

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const goBack = () => {
    navigate(-1);
  }

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/');
  }

  const goToHome = () => {
    navigate('/home');
  }

  let latitude = ''
  let longitude = ''
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    console.log("Geolocalização não suportada");
  }

  function success(position: any) {
    latitude = position.coords.latitude;
    longitude = position.coords.longitude;
  }

  function error() {
    console.log("Não foi possível achar sua localização");
  }

  const openMaps = (address: string, city: string, zipcode: string) => {
    if (address && city && zipcode) {
      window.open(`https://www.google.com/maps/dir/${latitude},${longitude}/${address}-${city}-${zipcode}`, '_blank')
    }
    window.open(`https://www.google.com/maps/dir/${latitude},${longitude}/${address}`, '_blank')
  }

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className="Wallet-header-container">
        <div>
          <AiOutlineLeft className='Home-icon' onClick={goBack} />
          <img src={logo} className="Wallet-logo-header" alt="logo" />
        </div>
        <div>
          <AiOutlineMenu className='Wallet-icon' onClick={toggleDrawer} />
        </div>
      </div>
      <div>
        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
          <div className="Home-drawer-link" onClick={goToHome} >
            <AiOutlineHome color='gray' size={20} />
            <p className='Home-drawer-link-text'>Home</p>
          </div>
          <Divider />
          <div className="Home-drawer-link" onClick={logout} >
            <AiOutlineLogout color='gray' size={18} />
            <p className='Home-drawer-link-text'>Sair</p>
          </div>
          <Divider />
        </Drawer>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="right">Endereço</TableCell>
                <TableCell align="right">Telefone</TableCell>
                <TableCell align="right">Tipo</TableCell>
                <TableCell align="right">Localização</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partner.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.address && row.number && row.city && row.zipcode ? (
                      <div>
                        {row.address}, {row.number} - {row.city}, {row.zipcode}
                      </div>
                    ) : (
                      <div>
                        {row.address}
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="right">{row.phone}</TableCell>
                  <TableCell align="right">{row.partner_type}</TableCell>
                  <TableCell align="right">
                    <Button className='geolocation-button'>
                      <b onClick={() => openMaps(row.address, row.city, row.zipcode)}>Abrir localização</b>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div >
  );
}

export default App;
