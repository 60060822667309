import axios from "axios";

const api = axios.create({
  baseURL: "https://api.prosaude.com.br"
});

api.interceptors.request.use(async config => {

  const token = (localStorage.getItem('token') as string);
  if (token && token !== '') {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = "/";
    }
  }
);

export default api;