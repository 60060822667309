import React, { useState } from 'react';
import './index.css';
import 'react-modern-drawer/dist/index.css';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineMenu, AiOutlineHome, AiOutlineLogout, AiOutlineDown, AiOutlineRight, AiFillExclamationCircle, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DeleteMedicineService from '../../services/DeleteMedicineService';
import Divider from "@material-ui/core/Divider";
import DoseItem from "./item";

import Checkbox  from '@mui/material/Checkbox';

function Dose({medicine, setIsDeleteOpen, setIsLoading, setMedicineId} : {medicine: any, setIsLoading: (any: boolean) => void, setIsDeleteOpen: (e: boolean)=> void, setMedicineId: (e: number) => void}) {

    const [isMedicineOpen, setIsMedicineOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const onClickRemove = () => {
        setMedicineId(medicine.id);
        setIsDeleteOpen(true);
    }

    const onClickEdit = () => {
        localStorage.setItem('medicineToEdit', JSON.stringify(medicine));
        navigate('/add-medicine');
    }

    const onClickCheckDose = () => {
        setIsLoading
        localStorage.setItem('medicineToEdit', JSON.stringify(medicine));
        navigate('/add-medicine');
    }

  return medicine.doses.length as number > 0 && !medicine.deleted ? (<div className="Medicine-detail-container">
            <div className='Medicine-container' onClick={() => setIsMedicineOpen(!isMedicineOpen)}>
                <div className='Medicine-item-container' >
                    <div className='Medicine-title-container'>
                        <p className='Medicine-name'>{medicine.name}</p>
                        <FaRegEdit style={{marginRight: '10px'}} onClick={onClickEdit} />
                        <FaRegTrashAlt onClick={onClickRemove} />
                    </div>

                    {isMedicineOpen ? <AiOutlineDown className='Medicine-details-icon' /> : <AiOutlineRight className='Medicine-details-icon' />}
                </div>

                {medicine && medicine.doses && medicine.doses.length > 0 && medicine.doses.map((dose:any) =>
                <DoseItem dose={dose} medicine={medicine} setIsLoading={setIsLoading} />)}
            </div>
            {isMedicineOpen && <Divider />}
            {isMedicineOpen && <ul className='Medicine-details-ul'>
                <li className='Medicine-detail'>
                    Nome: {medicine.name}
                </li>
                <li className='Medicine-detail'>
                    Dose: {medicine.dose}mg
                </li>
                <li className='Medicine-detail'>
                    Intervalo: {medicine.hoursinterval}h
                </li>
                <li className='Medicine-detail'>
                    Duração: {Math.ceil((Math.abs(new Date(medicine.finaldate).valueOf()-new Date(medicine.starteddate).valueOf()))/ (1000 * 3600 * 24))} dia(s)
                </li>
            </ul>}
        </div>
  ) : <></>;
}

export default Dose;
