import React from "react";
import { createBrowserRouter } from "react-router-dom";

import Login from "../pages/Login";
import Subscribe from "../pages/Subscription";
import Home from "../pages/Home";
import Reminders from "../pages/Reminders";
import RemindersMedicine from "../pages/RemindersMedicine";
import WhichMedicine from "../pages/WhichMedicine";
import Wallet from "../pages/Wallet";
import Geolocation from "../pages/Geolocation";
import RemindersAgenda from "../pages/RemindersAgenda";
import HealthCicle from "../pages/HealthCicle";
import Construction from "../pages/Construction";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import AddMedicine from "../pages/AddMedicine";
import Zendesk from "../pages/Zendesk";
import MedicineChecked from '../pages/MedicineChecked';
import FriendsMedicine from "../pages/FriendsMedicine";
import MedicineFriendTable from "../pages/MedicineFriendTable";
import ManageFriends from "../pages/ManageFriends";
import DoseFriendTable from "../pages/DoseFriendTable";

const Routes = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/subscribe",
    element: <Subscribe />,
  },
  {
    path: "/subscribe",
    element: <Subscribe />,
  },
  {
    path: "/reminders",
    element: <Reminders />,
  },
  {
    path: "/reminders-medicine",
    element: <RemindersMedicine />,
  },
  {
    path: "/which-medicine",
    element: <WhichMedicine />,
  },
  {
    path: "/friends-medicine",
    element: <FriendsMedicine />,
  },
  {
    path: "/medicine-friend-table/:id/:name",
    element: <MedicineFriendTable />,
  },
  {
    path: "/manage-friends",
    element: <ManageFriends />,
  },
  {
    path: "/dose-friend-table/:id/",
    element: <DoseFriendTable />,
  },
  {
    path: "/wallet",
    element: <Wallet />,
  },
  {
    path: "/geolocation",
    element: <Geolocation />,
  },
  {
    path: "/reminders-agenda",
    element: <RemindersAgenda />,
  },
  {
    path: "/health-cicle",
    element: <HealthCicle />,
  },
  {
    path: "/construction",
    element: <Construction />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  }, {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/add-medicine",
    element: <AddMedicine />,
  },
  {
    path: "/zendesk",
    element: <Zendesk />,
  },
  {
    path: "/medicine-checked",
    element: <MedicineChecked />,
  },
  {
    path: "/home",
    element: <Home />,
  },
]);

export default Routes;