import React, { useState, useEffect } from 'react';
import construcao from '../../assets/construcao.png';
import './index.css';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";


function App() {

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const logout = () => {
        localStorage.removeItem('token');
        navigate('/');
    }

    const goToHome = () => {
        navigate('/home');
    }

    useEffect(() => {
        const token = (localStorage.getItem('token') as string);

        if (!token) {
            navigate('/');
        }
    }, [])

  return (
    <div className="Construction">
        <AiOutlineMenu className='Construction-icon' onClick={toggleDrawer}/>
      <header className="Construction-header">
        <img src={construcao} className="Construction-logo" alt="logo" />
        <div className="Construction-text">Página em construção!</div>
      </header>
      <Drawer open={isOpen} onClose={toggleDrawer} direction='right'>
        <div className="Construction-link" onClick={goToHome} >Home</div>
        <div className="Construction-link" onClick={logout} >Sair</div>
    </Drawer>
    </div>
  );
}

export default App;
