import React, { useEffect, useState } from 'react';
import { Avatar, Button, Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import avatarIcon from '../../assets/avatar-icon.png';
import logo from '../../assets/logo-small.png';
import './index.css';
import { AiOutlineLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import GetListFriendship from '../../services/GetListFriendship';

interface DataType {
  person1id: string;
  person2id: string;
  name: string,
  email: string;
  status: string;
}

const App: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    GetListFriendship().finally(() => {
      setLoading(false);
    });
  }, [])

  const userId = JSON.parse(localStorage.getItem('userId') || '');
  const friendship = JSON.parse(localStorage.getItem('friends') || "[]");
  const friends = friendship.filter((friend: DataType) => friend.status === 'ACCEPTED');

  const goBack = () => {
    navigate(-1);
  }

  const goToMedicineFriendTable = (id: string, name: string) => {
    navigate(`/medicine-friend-table/${id}/${name}`);
  }

  return (
    <div>
      <div className='friend-screen'>
        <AiOutlineLeft className='Home-icon' onClick={goBack} />
        <img src={logo} className="Add_medicine-logo" alt="logo" />
      </div>
      <h2 className='Medicine-title-text'>
        Lista de amizades
      </h2 >
      <br />
      <div
        id="scrollableDiv"
        style={{
          height: 800,
          overflow: 'auto',
          padding: '0 16px',
          border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
      >
        <InfiniteScroll
          dataLength={5}
          next={() => friends}
          hasMore={friends.length > 5}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={<Divider></Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={friends}
            locale={{ 
              emptyText: (
                <div style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center', marginTop: '20px' }}>
                  Nenhuma conexão encontrada
                </div>
              )
            }}
            renderItem={(item: DataType) => (
              <List.Item key={item.email}>
                <List.Item.Meta
                  avatar={<Avatar src={avatarIcon} />}
                  title={item.name}
                  description={item.email}
                />
                <Button
                  className='friend'
                  shape="round"
                  onClick={() => goToMedicineFriendTable((item.person1id !== userId.toString() ? item.person1id : item.person2id), item.name)}
                >
                  Ver medicamento
                </Button>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default App;