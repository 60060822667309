import baseService from '../BaseService';

const LoginService = (phone: string, password: string) => {

    localStorage.setItem('token', JSON.stringify({ phone, password }));
    return baseService.post('/v1/auth', { phone, password }, { headers: { "Content-Type": "application/json" } })
        .then(response => {
            localStorage.setItem('zendesk', JSON.stringify(true));
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('userId', response.data.id)
            localStorage.setItem('name', response.data.name)
        })
};

export default LoginService;