import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import { AiOutlineLeft } from 'react-icons/ai';
import logo from '../../assets/logo-small.png';
import { useNavigate, useParams } from 'react-router-dom';
import GetDosesServiceFriend from '../../services/GetDosesServiceFriend';

interface DataType {
  id: string;
  name: string;
  dose: string;
  hoursinterval: string;
  createdat: string;
  starteddate: string;
  finaldate: string;
  duration?: number;
}

const App: React.FC = () => {
  const navigate = useNavigate();
  const { id, name } = useParams<{ id: string; name: string }>();
  const [dosesFriend, setDosesFriend] = useState<DataType[]>([]);

  useEffect(() => {
    GetDosesServiceFriend(id).finally(() => {
      const storedDosesFriend = JSON.parse(localStorage.getItem('dosesFriend') || '[]');
      const updatedDosesFriend = storedDosesFriend.map((doseFriend: DataType) => {
        const date1 = new Date(doseFriend.starteddate);
        const date2 = new Date(doseFriend.finaldate);

        const differenceInMilliseconds = date2.getTime() - date1.getTime();
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const differenceInDays = differenceInMilliseconds / millisecondsPerDay;

        const roundedDifferenceInDays = Math.round(differenceInDays);

        return {
          ...doseFriend,
          duration: roundedDifferenceInDays,
        };
      });
      setDosesFriend(updatedDosesFriend);
    });
  }, [id]);

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Medicamento',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, record: DataType) => {
        return (
          <a onClick={() => navigate(`/dose-friend-table/${record.id}`)}>{value}</a>
        );
      },
    },
    {
      title: 'Doses',
      dataIndex: 'dose',
      key: 'dose',
    },
    {
      title: 'Intervalo (em horas)',
      dataIndex: 'hoursinterval',
      key: 'hoursinterval',
    },
    {
      title: 'Duração (em dias)',
      key: 'duration',
      dataIndex: 'duration',
      render: (text: number) => {
        return (
          <div>
            {text} dias
          </div>
        );
      },
    },
  ];

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className='friend-screen'>
        <AiOutlineLeft className='Home-icon' onClick={goBack} />
        <img src={logo} className="Add_medicine-logo" alt="logo" />
      </div>
      <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: '1rem', fontWeight: '900'}}>
        <span>{name}</span>
      </div>
      <Table
        columns={columns}
        dataSource={dosesFriend.filter(item => {
          const finalDate = new Date(item.finaldate);
          return finalDate > new Date();
        })}
        locale={{
          emptyText: (
            <div style={{ fontWeight: 'bold', fontSize: '16px', color: '#555' }}>
              Nenhum medicamento pendente
            </div>
          )
        }}
      />
    </div>
  );
};

export default App;
