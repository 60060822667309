import baseService from '../BaseService';

const GetPartnerService = () => {
  return baseService.get(`/v1/partner`, { headers: { "Content-Type": "application/json" } })
    .then(response =>
      localStorage.setItem('partner', JSON.stringify(response.data))
    ).catch((err) => {
      localStorage.setItem('partner', JSON.stringify([]));
    })
};

export default GetPartnerService;