import baseService from '../BaseService';

const GetWalletService = () => {
  const userId = localStorage.getItem('token');

  baseService.get(`/v1/user/${userId}`, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async (res) => {
      localStorage.setItem('email', JSON.stringify(res.data.user[0].email))
    })
    .catch(async (err) => {
      localStorage.setItem('email', JSON.stringify([]));
    })

  const email = localStorage.getItem('email');

  return baseService.get(`https://api.prosaude.com.br/v1/wallet/${email}`, {
    headers: { "Content-Type": "application/json" }
  })
    .then(async (res) => {
      localStorage.setItem('wallet', JSON.stringify(res.data))
    })
    .catch(async (err) => {
      localStorage.setItem('wallet', JSON.stringify([]));
    })

};

export default GetWalletService;