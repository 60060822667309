import baseService from '../BaseService';

const ForgotPasswordService = async (phone: string) => {
    try {
        const response = await baseService.post('/v1/forgot-password', { phone }, {
            headers: { "Content-Type": "application/json" }
        });

        if (response.status === 200) {
            console.log('Sucesso:', response.data);
            return response.data;
        } else if (response.status === 400) {
            throw new Error('O telefone não existe.');
        }
    } catch (error: any) {
        console.error('Erro:', error.message || error);
        throw error;
    }
};

export default ForgotPasswordService;
