import baseService from '../BaseService';

const GetListFriendship = () => {
  const userId = localStorage.getItem('userId');

  return baseService.get(`/v1/friends/${userId}`, { headers: { "Content-Type": "application/json" } })
    .then(response =>
      localStorage.setItem('friends', JSON.stringify(response.data))
    ).catch((err) => {
      localStorage.setItem('friends', JSON.stringify([]));
    })
};

export default GetListFriendship;