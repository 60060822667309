import baseService from '../BaseService';

const GetDosesServiceFriend = (id) => {
    return baseService.get(`/v1/user/medicine/${id}`, { headers: { "Content-Type": "application/json" } })
        .then(response =>
            localStorage.setItem('dosesFriend', JSON.stringify(response.data))
        ).catch((err) => {
            localStorage.setItem('dosesFriend', JSON.stringify([]));
        })
};

export default GetDosesServiceFriend;