import baseService from '../BaseService';

const FriendsCount = () => {
    const userId = (localStorage.getItem('userId') as string);

    return baseService.get(`/v1/friends/count/${userId}`, { headers: { "Content-Type": "application/json" } })
        .then(response =>
            localStorage.setItem('friends-count', JSON.stringify(response.data))
        ).catch((err) => {
            localStorage.setItem('friends-count', JSON.stringify([]));
        });
};

export default FriendsCount;