import React, { useEffect, useState } from 'react';
import logo from '../../assets/soul-logo.png';
import './index.css';
import { Link } from 'react-router-dom';
import PasswordResetService from '../../services/PasswordResetService';
import { useNavigate } from "react-router-dom";

function App() {

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState();

  const navigate = useNavigate();

  const delay = (delayInms: number) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  useEffect(() => {
    const token = (localStorage.getItem('token') as string);

    if (token && token !== '') {
        navigate('/home');
    }

  }, []);

  const sendPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    PasswordResetService(email, code, password)
    .then(response => navigate('/login', { state: { reset: true } }))
    .catch(async error => {
      setError(error);
      await delay(3000);
      setError(undefined);
    });
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <form className="App-form" onSubmit={sendPassword} >
        <p className='Subscribe-form-title'>E-mail</p>
        <input className="Subscribe-form-input" type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="usuario@dominio.com.br" />
        <p className='Subscribe-form-title'>Código</p>
        <input className="Subscribe-form-input" type="number" value={code} onChange={(e) => setCode(e.target.value)} name="code" placeholder="000000" />
        <p className='Subscribe-form-title'>Senha</p>
        <input className="Subscribe-form-input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" placeholder="Senha" />
        <input className="App-form-submit" type="submit" value="Resetar senha" />
      </form>

      <Link className="App-link" to="/subscribe" >Não tem cadastro? Cadastre-se aqui</Link>

      {error && <div className='App-error-container' >Erro ao efetuar reset de senha!</div>}
    </div>
  );
}

export default App;
