import baseService from '../BaseService';

const AddMedicineService = (name: string, dose: string, intervalo: string, duracao: number, primeiroHorario: string) => {

    const userId = Number((localStorage.getItem('userId') as string).replace(/"/g, ''));
    const startedDate = new Date();

    function padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date: Date) {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-');
    }

    const startDateToService = formatDate(startedDate);
    let finalDate = new Date((startedDate.getTime() + duracao * 24 * 60 * 60 * 1000));

    const finalDateToService = formatDate(finalDate);

    return baseService.post('/v1/medicine', { userId, name, dose, startedDate: startDateToService, finalDate: finalDateToService, startedTime: primeiroHorario, hoursInterval: intervalo }, { headers: { "Content-Type": "application/json" } });

};

export default AddMedicineService;