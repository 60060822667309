import React, { useState, useEffect } from 'react';
import './index.css';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import Divider from "@material-ui/core/Divider";

function App() {

    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const logout = () => {
        localStorage.removeItem('token');
        navigate('/');
    }

    const goToHome = () => {
        navigate('/home');
    }

    const openMedicineList = () => {
        navigate('/reminders-medicine');
    }

    const openAgendaList = () => {
        navigate('/reminders-agenda');
    }

    useEffect(() => {
        const token = (localStorage.getItem('token') as string);

        if (!token) {
            navigate('/');
        }
    }, [navigate])

  return (
    <div className="Reminders">
      <div className="Home-header-container">
        <div className="Home-text">Lembretes</div>
        <AiOutlineMenu className='Home-icon' onClick={toggleDrawer}/>
      </div>
      <header className="Reminders-header">
        <div className="Reminders-list">

             <div className="Home-link" onClick={openMedicineList} >
                <p className='Home-link-title'>Remédios</p>
                <p className='Home-link-desc'>Gerencie seus remédios</p>
            </div>
            <div className="Home-link" onClick={openAgendaList} >
                <div className='Home-link-title' onClick={openAgendaList} >Agendamentos</div>
                <div className='Home-link-desc'>Gerencie sua agenda de consultas e exames</div>
            </div>
        </div>
      </header>
      <Drawer open={isOpen} onClose={toggleDrawer} direction='right'>
        <div className="Home-drawer-link" onClick={goToHome} >
            <AiOutlineHome color='gray' size={20} />
            <p className='Home-drawer-link-text'>Home</p>
        </div>
        <Divider />
        <div className="Home-drawer-link" onClick={logout} >
            <AiOutlineLogout color='gray' size={18} />
            <p className='Home-drawer-link-text'>Sair</p>
        </div>
        <Divider />
      </Drawer>
    </div>
  );
}

export default App;
