import React, { useState, useEffect } from 'react';
import './index.css';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import addNotification from 'react-push-notification';
import homeLogo from '../../assets/home-logo.png';
import logo from '../../assets/logo-small.png';
import { AiOutlineMenu, AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Zendesk from "react-zendesk";
import { ZendeskAPI } from '../Zendesk/config';
import GetListFriendship from '../../services/GetListFriendship';
import FriendsCount from '../../services/FriendsCountService';
import GetFriendsMedicineCount from '../../services/GetFriendsMedicineCount';
import InstallButton from '../../components/PWAButton';
import sGreen from '../../assets/sGreen.png';

interface DataType {
    id: string;
    name: string;
    dose: string;
    hoursinterval: string;
    createdat: string;
    starteddate: string;
    finaldate: string;
    duration?: number;
}

interface FriendDataType {
    id: string;
    name: string;
    email: string;
    status: string;
    phone: string;
    person1id: string;
    person2id: string;
}

interface DosesFriend {
    count: string;
}

function App() {
    const [isOpen, setIsOpen] = useState(false);
    const [zendesk, setZendesk] = useState(false);
    const [friendship, setFriendship] = useState<FriendDataType[]>([]);
    const [friendsCount, setFriendsCount] = useState<FriendDataType[]>([]);
    const userId = JSON.parse(localStorage.getItem('userId') || '');

    const navigate = useNavigate();

    const number = '2137158800';
    const text = 'Time%20de%20saude';

    const [dosesFriend, setDosesFriend] = useState<DosesFriend>({ count: '0' });

    useEffect(() => {
        GetFriendsMedicineCount(userId).finally(() => {
            const dosesCount = JSON.parse(localStorage.getItem('dosesCount') || '{}');
            setDosesFriend(dosesCount);
        });
    }, [userId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await FriendsCount();
                await GetListFriendship();
                const updatedFriendship = JSON.parse(localStorage.getItem('friends') || '[]');
                const data = JSON.parse(localStorage.getItem('friends-count') || '[]');
                setFriendship(updatedFriendship);
                setFriendsCount(data);
            } catch (error) {
                console.error('Erro ao buscar a lista de amizades:', error);
            } finally {
            }
        };

        fetchData();
    }, []);

    const receivedRequests = friendship.filter((request: FriendDataType) => (request.status === 'PENDING' && request.person2id === userId.toString()));

    const ZENDESK_KEY = '9ac78301-87e8-42be-a94c-ce88294a8c89';

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('zendesk');
        setZendesk(false);
        navigate('/');
    }

    const goToHome = () => {
        navigate('/home');
    }

    const goToHealthCicle = () => {
        navigate('/health-cicle');
    }

    const goToReminders = () => {
        navigate('/which-medicine');
    }

    const goToWallet = () => {
        navigate('/wallet');
    }

    const goToGeolocation = () => {
        navigate('/geolocation');
    }

    const goToManageFriends = () => {
        navigate('/manage-friends');
    }

    const handleLoaded = () => {
        ZendeskAPI("messenger", "open");
    };

    const goToWhatsapp = () => {
        window.open(`https://wa.me//55${number}?text=${text}`);
    }

    useEffect(() => {
        const token = localStorage.getItem('token') as string;

        if (!token) {
            navigate('/');
        }
    }, [navigate])

    useEffect(() => {
        const isKendesk = localStorage.getItem('zendesk');

        if (isKendesk) {
            setZendesk(true);
        }

        const token = localStorage.getItem('token') as string;

        if (!token) {
            navigate('/');
        }
    }, [])

    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=9ac78301-87e8-42be-a94c-ce88294a8c89';
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const doseFriendNumber = parseInt(dosesFriend.count)

    const clickToNotify = () => {
        addNotification({
          title: 'Medicamento',
          message: 'Está na hora do seu medicamento',
          duration: 3000,
          icon: sGreen,
          native: true,
          onClick: ()=> window.location.assign('https://app.prosaude.com.br/'),
        });
      };
      
    return (
        <div className="Home">
            <div className="Home-header-container">
                <img src={logo} className="Home-header-logo" alt="logo" />
                <AiOutlineMenu className='Home-icon' onClick={toggleDrawer} />
            </div>
            <header className="Home-header">
                <div className="Home-link-container">
                    <div className="Home-link" onClick={goToReminders} >
                        <p className='Home-link-title'>Medicamentos</p>
                        {doseFriendNumber > 0 && (
                            <div className="notification-badge">{dosesFriend.count}</div>
                        )}
                        {/* <div className="notification-badge"><NotificationsNoneIcon /></div> */}
                    </div>
                    <div className="Home-link" onClick={goToWallet} >
                        <p className='Home-link-title'>Carteirinha</p>
                    </div>
                    <div className="Home-link" onClick={goToGeolocation} >
                        <p className='Home-link-title'>Benefícios</p>
                    </div>
                    <div className="Home-link" onClick={goToManageFriends} >
                        <p className='Home-link-title'>Amizades | Conexões</p>
                        {receivedRequests.length > 0 ? (
                            <div className="notification-badge">{receivedRequests.length}</div>
                        ) : ''}
                    </div>
                    <div className="Home-link">
                        <InstallButton />
                    </div>
                    <div className="Home-link" onClick={clickToNotify} >
                        <p className='Home-link-title'>Notificação</p>
                    </div>
                    <div className="Home-logo-container">
                        <img src={homeLogo} className="Home-logo" alt="logo" />
                    </div>
                </div>
            </header>
            {/* {zendesk && <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />} */}
            <Drawer open={isOpen} onClose={toggleDrawer} direction='right'>
                <div className="Home-drawer-link" onClick={goToHome} >
                    <AiOutlineHome color='gray' size={20} />
                    <p className='Home-drawer-link-text'>Home</p>
                </div>
                <Divider />
                <div className="Home-drawer-link" onClick={logout} >
                    <AiOutlineLogout color='gray' size={18} />
                    <p className='Home-drawer-link-text'>Sair</p>
                </div>
                <Divider />
            </Drawer>
        </div>
    );
}

export default App;
