import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/logo-small.png';
import { AiOutlineLeft } from 'react-icons/ai';
import { InfoCircleOutlined } from '@ant-design/icons';
import GetDoseServiceFriend from '../../services/GetDoseServiceFriend';
import './index.css';
import { Tooltip, Switch } from 'antd';

interface Dose {
  id: number;
  name: string;
  status: boolean;
  dose: string;
  interval: string;
  duration: string;
  hour: string;
  date: string;
}

const currentDate = new Date();

const AddMedicineCalendar: React.FC = () => {
  const [currentStartDate, setCurrentStartDate] = useState(new Date());
  const [dosesFriend, setDoseFriend] = useState<Dose[]>([]);
  const [filter, setFilter] = useState<'all' | 'pending' | 'taken' | 'overdue'>('all');

  const { id } = useParams();

  useEffect(() => {
    GetDoseServiceFriend(id).finally(() => {
      const data = JSON.parse(localStorage.getItem('doses-friend-today') || '[]');
      setDoseFriend(data);
    });
  }, [id]);
  
  const navigate = useNavigate();

  const getDosesForDate = (date: Date) => {
    const dateString = date.toISOString().split('T')[0];
    return dosesFriend.filter(dose => dose.date === dateString);
  };

  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const handlePreviousWeek = () => {
    setCurrentStartDate(addDays(currentStartDate, -7));
  };

  const handleNextWeek = () => {
    setCurrentStartDate(addDays(currentStartDate, 7));
  };

  const renderDays = () => {
    const days = [];
    for (let count = 0; count < 7; count++) {
      const date = addDays(currentStartDate, count);
      let dosesForDate = getDosesForDate(date);

      dosesForDate = dosesForDate.filter(dose => {
        if (filter === 'pending' && new Date(dose.date) > currentDate) return true;
        if (filter === 'taken' && new Date(dose.date) < currentDate && dose.status) return true;
        if (filter === 'overdue' && new Date(dose.date) < currentDate && !dose.status) return true;
        return filter === 'all';
      });

      days.push(
        <div className="day" key={count}>
          <div className="date">
            {date.toLocaleDateString('pt-BR', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </div>
          {dosesForDate.map(dose => (
            <>
              {new Date(dose.date) > currentDate && (
                <div key={dose.id} className="dose-wait">
                  {dose.name} - {dose.hour}
                  <Tooltip placement="top" title="Dose agendada">
                    <InfoCircleOutlined className="dose-wait-button" />
                  </Tooltip>
                </div>
              )}

              {new Date(dose.date) < currentDate && dose.status && (
                <div key={dose.id} className="dose-check">
                  {dose.name} - {dose.hour}
                  <Tooltip placement="top" title="Dose tomada">
                    <InfoCircleOutlined className="dose-check-button" />
                  </Tooltip>
                </div>
              )}

              {new Date(dose.date) < currentDate && !dose.status && (
                <div key={dose.id} className="dose-not-check">
                  {dose.name} - {dose.hour}
                  <Tooltip placement="top" title="Dose atrasada">
                    <InfoCircleOutlined className="dose-not-check-button" />
                  </Tooltip>
                </div>
              )}
            </>
          ))}
        </div>
      );
    }
    return days;
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="calendar-container">
      <div className="friend-screen">
        <AiOutlineLeft className="Home-icon" onClick={goBack} />
        <img src={logo} className="Add_medicine-logo" alt="logo" />
      </div>

      <span>Filtrar por:</span>
      <div className="filter-container" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Switch
          checked={filter === 'pending'}
          onChange={() => setFilter(filter === 'pending' ? 'all' : 'pending')}
          style={{ backgroundColor: filter === 'pending' ? '#27B589' : undefined }}
        />{' '}
        Pendentes
        <Switch
          checked={filter === 'taken'}
          onChange={() => setFilter(filter === 'taken' ? 'all' : 'taken')}
          style={{
            marginLeft: '1rem',
            backgroundColor: filter === 'taken' ? '#27B589' : undefined,
          }}
        />{' '}
        Tomadas
        <Switch
          checked={filter === 'overdue'}
          onChange={() => setFilter(filter === 'overdue' ? 'all' : 'overdue')}
          style={{
            marginLeft: '1rem',
            backgroundColor: filter === 'overdue' ? '#27B589' : undefined,
          }}
        />{' '}
        Atrasadas
      </div>

      <div className="navigation-buttons">
        <button onClick={handlePreviousWeek}>Anterior</button>
        <button onClick={handleNextWeek}>Próximo</button>
      </div>

      <div className="days-container">{renderDays()}</div>
    </div>
  );
};

export default AddMedicineCalendar;
