import React, { useState, useEffect } from 'react';
// import './index.css';
import ReactDOM from "react-dom";
import Zendesk from "react-zendesk";
const ZENDESK_KEY = "9ac78301-87e8-42be-a94c-ce88294a8c89";


function App() {

    const setting = {
        color: {
          theme: "#000"
        },
        launcher: {
          chatLabel: {
            "en-US": "Need Help",
            "pt-BR": "Precisa de Ajuda?"
          }
        },
        contactForm: {
          fields: [
            { id: "description", prefill: { "*": "Entre em contato conosco" } }
          ]
        }
      };

  return (
    <div>
        <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('is loaded')} />
    </div>
  );
}

export default App;
