import React, { useEffect, useState } from 'react';
import './index.css';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useNavigate } from "react-router-dom";
import GetWalletService from '../../services/GetWalletService';
import { AiOutlineMenu, AiOutlineHome, AiOutlineLogout, AiOutlineLeft } from "react-icons/ai";
import Divider from "@material-ui/core/Divider";
import logo from '../../assets/logo-small.png';
import { Link } from 'react-router-dom';
import carteirinhaSoulImagem from '../../assets/carteirinha-soul-imagem.png';
import sGreen from '../../assets/sGreen.png';
import sYellow from '../../assets/sYellow.png';

function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
}

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    GetWalletService().finally(async () => {
      setIsLoading(false);
    });
  }, [])
  const data = JSON.parse(localStorage.getItem('wallet') || "{}");
  const name = localStorage.getItem('name') || '';
  const navigate = useNavigate();
  const number = '2137158800';
  const text = 'atualizar assinatura';

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const goBack = () => {
    navigate(-1);
  };

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const goToHome = () => {
    navigate('/home');
  };

  const isBasicStatus = (walletData: any) => {
    return (
      (walletData.userStatus === 'active' && walletData.billStatus === 'inactive') ||
      walletData.userStatus === 'inactive'
    );
  };

  return (
    <div className="Wallet-screen">
      <div className="Wallet-header-container">
        <div>
          <AiOutlineLeft className="Home-icon" onClick={goBack} />
          <img src={logo} className="Wallet-logo-header" alt="logo" />
        </div>
        <div>
          <AiOutlineMenu className="Wallet-icon" onClick={toggleDrawer} />
        </div>
      </div>
      <Drawer open={isOpen} onClose={toggleDrawer} direction="right">
        <div className="Home-drawer-link" onClick={goToHome}>
          <AiOutlineHome color="gray" size={20} />
          <p className="Home-drawer-link-text">Home</p>
        </div>
        <Divider />
        <div className="Home-drawer-link" onClick={logout}>
          <AiOutlineLogout color="gray" size={18} />
          <p className="Home-drawer-link-text">Sair</p>
        </div>
        <Divider />
      </Drawer>
      <div className="Wallet">
        <div className="Wallet-divider">
          <div className="Wallet-left">
            <div>
              {data.wallet && isBasicStatus(data.wallet) ? (
                <img src={sYellow} className="Image-wallet" alt="logo" />
              ) : (
                <img src={sGreen} className="Image-wallet" alt="logo" />
              )}
            </div>
          </div>
          <div className="Wallet-right">
            <div className="Wallet-right-text">
              {data.wallet ? (
                <div>
                  <p>
                    nome: <b>{data.wallet.name}</b>
                  </p>
                  <p>
                    status: {data.wallet.userStatus === 'active' && data.wallet.billStatus === 'active' && (
                      <b className="status-active-active">ativo</b>
                    )}
                  </p>
                  {isBasicStatus(data.wallet) && (
                    <div>
                      <b>básico</b>
                      <b>
                        <Link className="upgrade-button" to={`https://api.whatsapp.com/send?phone=55${number}&text=${text}`}>faça upgrade</Link>
                      </b>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p>
                    nome: <b>{name}</b>
                  </p>
                  <p>
                    status: <b>básico</b>
                  </p>
                  <div>
                    <b>
                      <Link className="upgrade-button" to={`https://api.whatsapp.com/send?phone=55${number}&text=${text}`}>faça upgrade</Link>
                    </b>
                  </div>
                </div>
              )}
            </div>
            <img src={logo} className="Wallet-logo" alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;