import baseService from '../BaseService';

const ChangeFriendStatus = (id: string, status: string) => {

  return baseService.put('v1/friends/invite', { id, status }, { headers: { "Content-Type": "application/json" } })
    .then(response => {
      localStorage.setItem('id', response.data.id);
      localStorage.setItem('status', response.data.status)
    })
};

export default ChangeFriendStatus;