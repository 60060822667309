import baseService from '../BaseService';

const GetUserByPhoneService = (phone: string) => {
  return baseService.get(`/v1/friends/user/phone/${phone}`, { headers: { "Content-Type": "application/json" } })
    .then(response => {
      localStorage.setItem('user-by-phone', JSON.stringify(response.data));
      return response.data;     })
    .catch(() => {
      localStorage.setItem('user-by-phone', JSON.stringify(null));
      return null;
    });
};

export default GetUserByPhoneService;
