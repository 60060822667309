import React, { useEffect, useState } from 'react';
import './index.css';
import 'react-modern-drawer/dist/index.css';
import { AiFillExclamationCircle } from "react-icons/ai";
import Checkbox from '@mui/material/Checkbox';
import CheckDoseService from '../../services/CheckDoseService';

function padWithLeadingZeros(num: number, totalLength: number) {
    return String(num).padStart(totalLength, '0');
  }

function DoseItem({dose, medicine, setIsLoading} : { medicine: any, dose: any, setIsLoading: (any: boolean) => void }) {

    const onClickCheckDose = () => {
        setIsLoading(true);
        CheckDoseService(dose.id).finally(() => {
            window.location.reload();
        })
    }
    
    const today = `${new Date().getFullYear()}-${String(new Date().getMonth() +1).padStart(2, "0")}-${padWithLeadingZeros(new Date().getDate(), 2)}`
    
    if(dose.date !== today) return null

  return ( <div className="Medicine-dose-container">
              <div className='Medicine-dose-item-container'>
                    <Checkbox
                    sx={{
                        padding: 0,
                        '& .MuiSvgIcon-root': { fontSize: 18 },
                    }}
                        checked={dose.status}
                        inputProps={{ 'aria-label': 'controlled' }}
                    onClick={onClickCheckDose}
                    />
                    <p className={`Medicine-dose-name${dose.status ? '-checked' : ''}`}>{`${medicine.name} - ${dose.hour}h`}</p>
                    {!dose.status && (new Date()).getHours() - dose.hour.split(':')[0] > 0 && <AiFillExclamationCircle className='Medicine-alert-icon' />}
                </div>
            </div>
  );
}

export default DoseItem;
